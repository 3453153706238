<template>
  <main>
    <div class="card-container">
      <div class="card" id="main" v-scroll-reveal>
        <img src="../assets/me.png" alt="my icon"/>
        <div>
          <h1>ilies t.</h1><h2>software & cloud engineer, specializing in data and cyber security</h2>
        </div>
      </div>
      <div class="card" v-scroll-reveal="{delay: 700}">
        <h2>from paris, france</h2>
      </div>
    </div>
    <div class="card-container">
      <div class="card" v-scroll-reveal="{delay: 800}">
        <h2>currently software & cloud engineer @ onepoint</h2>
      </div>
      <div class="card" id="contact-me" v-scroll-reveal="{delay: 900}">
        <h2>feel free to contact me!</h2>
        <a href="https://linkedin.com/in/ilies-t">contact me</a>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from 'vue';

export default Vue.component('Main', {});
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--default-space);
}

#main {
  > img {
    width: 100px;
    border-radius: 100px;
  }
}

#contact-me {
  height: 100%;
  background-color: var(--black);
  color: var(--white);

  > a {
    background-color: var(--white);
    color: var(--black);
    padding: 20px 10px;
    text-align: center;
    border-radius: calc(var(--default-space)/2);
    font-family: 'wide', sans-serif;
  }
}
@media screen and (orientation:portrait) {
  main {
    flex-flow: column nowrap;
  }
}
</style>
