<template>
  <div class="card-container" id="expertise">
    <div class="card" v-scroll-reveal="{delay: 1000}">
      <h2>expertise</h2>
    </div>
    <div class="card-container-horizontal">
      <div class="card-container">
        <div class="card" v-scroll-reveal="{delay: 800}">
          <p>software development (api, web)</p>
          <div :style="{ 'backgroundImage': 'url(' + require('../assets/illustration/desktop.png') + ')' }" v-scroll-reveal="{delay: 1200}" />
        </div>
        <div class="card" v-scroll-reveal="{delay: 800}">
          <p>cyber security (reverse engineering, software)</p>
          <div :style="{ 'backgroundImage': 'url(' + require('../assets/illustration/lock.png') + ')' }" v-scroll-reveal="{delay: 1200}" />
        </div>
      </div>
      <div class="card-container">
        <div class="card" v-scroll-reveal="{delay: 800}">
          <p>databases (rdbms, dbms, key-value)</p>
          <div :style="{ 'backgroundImage': 'url(' + require('../assets/illustration/folder.png') + ')' }" v-scroll-reveal="{delay: 1200}" />
        </div>
        <div class="card" v-scroll-reveal="{delay: 800}">
          <p>cloud (gcp, aws, azure)</p>
          <div :style="{ 'backgroundImage': 'url(' + require('../assets/illustration/cloud.png') + ')' }" v-scroll-reveal="{delay: 1200}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.component('Expertise', {});
</script>

<style lang="scss" scoped>
#expertise {
  padding-bottom: 100px;

  > .card {
    text-align: center;
  }

  > .card-container-horizontal {
    flex-wrap: nowrap;

    > .card-container {
      flex: 1;

      > .card {
        padding-left: 110px;
        overflow: hidden;

        > div {
          position: absolute;
          left: 0;
          height: 100%;
          width: 150px;
          background-size: 45px;
          background-repeat: space;
          z-index: -1;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, var(--grey) 100%);
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (orientation:portrait) {
  #expertise > .card-container-horizontal {
    flex-direction: column;
  }
}
</style>
